<!-- Informes APDS -->

<template>
  <div class="fondosR" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>


        <!-- Botonera -->
        <div class="pa-2">
          <baseBtraExtra class="conflex"
            :permExtra="permExtra"
            :modulo="btExtCfg"
            @onEvent="execAccion">
          </baseBtraExtra>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:950px">
          <div class="conflex">
            <div class="columna" style="width:32%">

              <!-- Menú -->
              <div class="cab">Tipos de Informes</div>
              <v-sheet v-bind="$cfg.styles.marco">
                <v-treeview
                  :items="recordsRead[0]"
                  item-key="d"
                  item-text="n"
                  item-children="c"
                  activatable
                  rounded
                  open-on-click
                  return-object
                  @update:active="changeInf">

                  <!-- no puede deseleccionar ya seleccionado -->
                  <template v-slot:label="{ item, active }">
                    <div @click="active ? $event.stopPropagation() : null" style="width:100%;margin:-5px;padding:5px">
                      {{ item.n }}
                    </div>
                  </template>

                </v-treeview>
              </v-sheet>
            </div>

            <div class="columna" style="width:66%;margin-left:10px">
              <div class="cab">Condiciones</div>

              <v-sheet v-bind="$cfg.styles.marco">

                <div style="display:flex">
                  <v-select
                      style="width:30%"
                      v-bind="$select"
                      v-model="ct.ord[2]"
                      :label="ct.ord[1]"
                      :items="ord"
                      item-value="d"
                      item-text="n">
                  </v-select>
                  <div style="width:70%"></div>
                </div>

                <!-- programa, tipo, estado -->
                <div style="display:flex">
                  <v-select
                      style="width:30%"
                      v-bind="$select"
                      v-model="ct.ano[2]"
                      :label="ct.ano[1]"
                      :items="recordsRead[10]"
                      item-value="d"
                      item-text="d"
                      @change="changeData(0)">
                  </v-select>
                  <v-select
                      style="width:70%"
                      v-bind="$select"
                      v-model="ct.plan[2]"
                      :label="ct.plan[1]"
                      :items="recordsRead[2]"
                      item-value="d"
                      item-text="n"
                      @change="changeData(0)">
                  </v-select>
                  <!-- <v-select
                      style="width:35%"
                      v-bind="$select"
                      v-model="ct.tip[2]"
                      :label="ct.tip[1]"
                      :items="recordsRead[3]"
                      item-value="d"
                      item-text="n"
                      @change="changeData">
                  </v-select> -->

                </div>

                <!-- Periodo Fechas -->
                <div style="display:flex">
                  <uiMultiFilterFechas
                    style="width:70%"
                    :items="recordsRead[5]"
                    :ct="ct"
                    :ctrl="ct.fecha"
                    tipo="periodo"
                    >
                  </uiMultiFilterFechas>
                  <v-select
                      style="width:30%"
                      v-bind="$select"
                      v-model="ct.std[2]"
                      :label="ct.std[1]"
                      :items="recordsRead[4]"
                      item-value="d"
                      item-text="n">
                  </v-select>
                </div>

                <!-- hoteles, cupo -->
                <div style="display:flex">
                  <v-select
                      style="flex: 0 0 70%"
                      v-bind="$select"
                      v-model="ct.hot[2]"
                      :label="ct.hot[1]"
                      :items="itemsHoteles"
                      item-value="d"
                      item-text="n"
                      @change="changeData(1)">
                  </v-select>
                  <v-select
                      style="flex: 0 0 30%"
                      v-bind="$select"
                      v-model="ct.turno[2]"
                      :label="ct.turno[1]"
                      :items="itemsTurnos"
                      item-value="d"
                      item-text="n"
                    />
                </div>

                <!-- agencia -->
                <div style="display:flex">
                  <!-- <ctrlF style="flex: 0 0 70%" :ct="ct.age"></ctrlF> -->
                  <v-select
                      style="flex: 0 0 70%"
                      v-bind="$select"
                      v-model="ct.age[2]"
                      :label="ct.age[1]"
                      :items="itemsAgencias"
                      item-value="d"
                      item-text="n"
                      @change="changeData(2)"
                    />
                </div>

                <!-- <div style="display:flex">
                    <uiText
                        :label="ct.txt[1]"
                        v-model="ct.txt[2]">
                    </uiText>
                </div> -->

                <!-- <div style="display:flex">
                  <uiText
                    style="width:70%"

                    :label="ct.ori[1]"
                    v-model="ct.ori[2]">
                  </uiText>
                  <v-select
                    style="flex: 0 0 30%"
                    v-bind="$select"
                    v-model="ct.tra[2]"
                    :label="ct.tra[1]"
                    :items="recordsRead[6]"
                    item-value="d"
                    item-text="n">
                  </v-select>
                </div> -->

              </v-sheet>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixR } from "@/mixins/mixR.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";

  export default {
    mixins: [mixR],
    components: { baseHeader, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "fondosR",
          titulo:"INFORMES TELEFONICA FFSS",
          name:"fondosR",
          mView:'',
          pView:[]
        },

        ord: [],
        //itemsZonas:[],
        itemsAgencias:[],
        itemsHoteles:[],
        itemsTurnos:[],

      };
    },



    methods: {
      iniDataParticular() {
        //console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.btExtCfg.btnsAccion= [
          { accion:'verPdf', btn:"pdf" },
          { accion:'verExcel', btn:"excel" }

        ];

        // coordinadores de servicio
        //this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();
        //this.itemsZonas.unshift({id:'0',name:'( Selecciona )'});

      },

      stRecordsGetFin() {
        // informe omision
        //this.ct.inf[2]=this.recordsRead[0][0];
        this.changeInf([this.recordsRead[0][0]]);

        // agencias
        this.itemsAgencias=this.recordsRead[7];
        this.itemsAgencias.unshift({d:'0',n:'( Selecciona )'});

        // hoteles leidos
        this.itemsHoteles=this.recordsRead[8];
        this.itemsHoteles.unshift({d:'0',n:'( Selecciona )'});
        // hoteles leidos
        this.itemsTurnos=this.recordsRead[9];
        this.itemsTurnos.unshift({d:'0',n:'( Selecciona )'});

        // Año por omision el último ...
        let aa=new Date().getFullYear().toString();
        //this.ct.ano[2]=this.recordsRead[10][0].d;
        this.ct.ano[2]=(aa<this.recordsRead[10][0].d ? aa : this.recordsRead[10][0].d);

      },

      async changeData(evt) {
        // llamada API
        let param= { apiArg:this.apiArgs.data, args:{ ctrls:this.ctrl2record(this.ct, {}, false) }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       //console.log('***** changeData*', evt, this.ct.ano[2], apiResult);

        // hoteles, turnos
        this.itemsHoteles=this.recordsRead[8]=apiResult.r[0];
        this.itemsHoteles.unshift({d:'0',n:'( Selecciona )'});
        this.itemsTurnos=this.recordsRead[9]=apiResult.r[1];
        this.itemsTurnos.unshift({d:'0',n:'( Selecciona )'});

        // dato inicial combos
        if (evt==0||evt==2) this.ct.hot[2]=this.ct.turno[2]='0';
        if (evt==1) this.ct.turno[2]='0';

      },

      // muestro informe en pdf
      verPdf() {
        this.showReport("pdf");
      },

      // muestro informe en excel
      verExcel() {
        this.showReport("xls");
      },


      // muestro informe
      async showReport(tip) {
        // compruebo si hay seleccionado un Informe
        if (!this.ct.inf[2]) {
          this.$root.$alert.open('Debes seleccionar un informe', 'error');
          return;
        }

        this.report(tip);
      }

    }
  };
</script>
